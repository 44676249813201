<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "線上詢問列表",
      items: [
        {
          text: "客服管理",
          href: "/onlineinquiry",
        },
        {
          text: "線上詢問列表",
          active: true,
        }
      ],
      typeOptions: [
        { id: 0, name: " 全部" },
        { id: 1, name: "中天電視" },
        { id: 2, name: "中天新聞會員" },
        { id: 3, name: "我要爆料" },
        { id: 4, name: "我要投稿" },
        { id: 5, name: "商務合作" },
        { id: 6, name: "公關合作" },
        { id: 7, name: "快點購購物網" },
        { id: 8, name: "其他問題" }
      ],
      statusOptions: [
        { id: 0, name: "全部"},
        { id: 1, name: " 未處理"},
        { id: 2, name: "處理中"},
        { id: 3, name: "已結案"}
      ],
      searchData: {
        word: '',
        type: 0,
        status: 0, 
      },
      selectedStatus: [0, 1, 2, 3],
      selectedType: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: 'id',
      fields: [
        {
          key: "id",
          label: "#",
          sortable: true,
        },
        {
          key: "content",
          label: "留言內容",
          sortable: true,
          class: 'text-nowrap'
        },
        {
          key: "type",
          label: "問題類型",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "created_at",
          label: "留言時間",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "status",
          label: "案件狀態",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "updated_at",
          label: "更新時間",
          sortable: false,
          class: 'width-auto center'
        }
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.reloadTable()
  },
  methods: {
    reloadTable() {
      this.$refs.inquiryTable.refresh()
    },
    getInquiryList(_table, _callback) {
      const vm = this


      const searchParams = {
        word: this.searchData.word || null,
        type: this.searchData.type ?? null,
        status: this.searchData.status ?? null,
        per_page: this.perPage || 10,
        page: this.currentPage || 1,
        sort: this.sortDesc ? 'desc' : 'asc',
        sort_by: this.sortBy || 'id',
      }

      this.$root.apis.getCustomerServiceList(
        searchParams,
        function(_response) {
          const result = _response.body.data
          vm.currentPage = result.page
          vm.totalRows = result.total
          vm.perPage = result.per_page

          if (typeof _callback === 'function') {
            vm.tableData= result.orders.map(_order => ({..._order, content: vm.formatContent(_order.content)}))
            _callback(vm.tableData)
          }
        },
        function(_error) {
          console.error(_error.body.data)
          if (typeof _callback === 'function') {
            _callback([])
          }
        }
      )
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeStatus(_status){
      // check all
      if (_status.includes(0)) {
        this.selectedStatus = this.statusOptions.map(_status => _status.id)
        this.searchData.status = 0
        // uncheck all
      } else if (this.searchData.status === 0 && !_status.includes(0)) {
        this.selectedStatus = []
        this.searchData.status = null
        // check others
      } else {
        this.selectedStatus = _status.length > 0 ? _status.slice(-1) : []
        this.searchData.status = this.selectedStatus[0] || null
      }
      this.reloadTable()
    },
    changeType(_type) {
      // check all
      if (_type.includes(0)) {
        this.selectedType = this.typeOptions.map(_type => _type.id)
        this.searchData.type = 0
        // uncheck all
      } else if (this.searchData.type === 0 && !_type.includes(0)) {
        this.selectedType = []
        this.searchData.type = null
        // check others
      } else {
        this.selectedType = _type.length > 0 ? _type.slice(-1) : []
        this.searchData.type = this.selectedType[0] || null
      }
      this.reloadTable()
    },
    formatContent(_content) {
      if (!_content || _content === '') return null
      const row1 = _content.slice(0, 10)
      const row2 = _content.slice(10, 20)
      if (row2) {
        return [row1, row2.slice(0, 8) + '...']
      }
      return row1.length > 8 ? [row1.slice(0, -2) + '...'] : [row1]
    },
  },
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title" :items="items")
  .row
    .col-lg-10.pl-2
      .header-row.mb-3
        .header-box 
          .search-box.col-lg-3
            input.form-control#tip(
              v-model="searchData.word"
              type="text",
              placeholder="Search..."
              @keydown="reloadTable"
            )
            i.ml-2.fas.fa-search
        .checkbox-group.col-lg-12.mt-3
          b-form-group
            b-form-checkbox-group#checkbox-group-1(
               :checked="selectedStatus"
               @input="changeStatus($event)"
               name="status_checkbox"
              )
              b-form-checkbox(
                v-for="_status in statusOptions" 
                :key="_status.id" 
                :value="_status.id"
                :disabled="searchData.status === 0 && _status.id !== 0"
              ) {{_status.name}}
          b-form-group
            b-form-checkbox-group#checkbox-group-2(
                :checked="selectedType"
                @input="changeType($event)"
                name="type_checkbox"
              )
              b-form-checkbox(
                v-for="_type in typeOptions" 
                :key="_type.id" 
                :value="_type.id"
                :disabled="searchData.type === 0 && _type.id !== 0"
              ) {{_type.name}}
    //- 表格
    .col-lg-10.mt-2.ml-1
      .card
        .card-body        
          //- Table
          .row
            .col-12
              b-table(
                head-variant="light"
                ref="inquiryTable"
                :items="getInquiryList",
                :fields="fields",
                responsive,
                :per-page="perPage",
                :current-page="currentPage",
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc",
                :filter="filter",
                :filter-included-fields="filterOn",
                @filtered="onFiltered"
              )

                template(v-slot:cell(content)="data")
                  span(v-if="!data.item.content") -
                  b-button.table-btn(v-else :to="`/onlineinquiry/setting/${data.item.id}`")
                    div.d-flex.flex-column.align-items-start.text-start
                      span {{ data.item.content[0] }}
                      span {{ data.item.content[1] }}

                template(v-slot:cell(type)="data")
                  span {{ typeOptions.find(_type => _type.id === data.item.type)?.name || '-' }}

                template(v-slot:cell(created_at)="data")
                  span {{ data.item.created_at.slice(0, -3) || '-' }}
                
                template(v-slot:cell(status)="data")
                  span.text-status-warning(v-if="data.item.status === 0") 未處理
                  span(v-else-if="data.item.status === 1") 處理中
                  span(v-else-if="data.item.status === 2") 已結案
                  span(v-else) -

                template(v-slot:cell(updated_at)="data")
                  span(v-if="data.item.status === 0") -
                  div(v-else)
                    span {{ data.item.updated_at.slice(0, -3) || '-' }}
                    br
                    span.mt-1 {{ data.item.editor_name || '-' }}

          .row.mb-md-2
            .col-6
              div(id="tickets-table_length", class="dataTables_length")
                label.d-inline-flex.align-items-center Show
                  b-form-select.mr-1.ml-1(v-model="perPage", size="sm", :options="pageOptions") 
                  | entries
            .col
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
<style lang="sass" scoped>
.row
  .header-row
    .header-title
      font-size: 24px
      font-weight: 700
    .header-box
      display: flex
      justify-content: space-between
      .form-group
        display: flex
        align-items: center
  .search-box
    width: 16rem
    position: relative
    #tip
      padding: 0.5rem 2rem
      background-repeat: no-repeat
      background-position: 10px 7px
      line-height: 4rem
    .fa-search
      position: absolute
      top: 35%    
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
  
.text-status-warning
  color: #FF846E


::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  &.delete
    color: #333333

.text-start
  text-align: start

.text-nowrap
  white-space: nowrap
</style>
